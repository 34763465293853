<template>
  <div>
    <div class="mb-4 md:mb-20">
      <div class="flex flex-col md:flex-row gap-4 w-full">
        <div>
          <div class="flex items-center">
            <Avatar
              size="large"
              class="mr-4"
              :src="`https://storage.googleapis.com/cavea-avatar-service/twitch/${streamerHandle}/avatar.png`"
            />

            <div>
              <Title :title="streamerHandle" size="large" class="capitalize" />
              <Title
                v-if="streamerData && streamerData.info && streamerData.info.broadcastingLanguage"
                :title="streamerData.info.broadcastingLanguage"
                size="small"
                class="capitalize"
              />
            </div>
          </div>

          <div class="flex gap-2 mt-4 ml-auto">
            <a
              v-if="streamerHandle"
              :href="`https://twitch.tv/${streamerHandle}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icon icon="twitch" :thumbnail="true" width="20" height="20" />
            </a>

            <a
              v-if="
                streamerData && streamerData.social && streamerData.social.youtube && streamerData.social.youtube.handle
              "
              :href="`https://youtube.com/channel/${streamerData.social.youtube.handle}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icon icon="youtube" :thumbnail="true" width="20" height="20" />
            </a>

            <a
              v-if="
                streamerData && streamerData.social && streamerData.social.twitter && streamerData.social.twitter.handle
              "
              :href="`https://twitter.com/${streamerData.social.twitter.handle}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icon icon="twitter" :thumbnail="true" width="20" height="20" />
            </a>

            <a
              v-if="
                streamerData &&
                streamerData.social &&
                streamerData.social.instagram &&
                streamerData.social.instagram.handle
              "
              :href="`https://instagram.com/${streamerData.social.instagram.handle}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icon icon="instagram" :thumbnail="true" width="20" height="20" />
            </a>

            <a
              v-if="
                streamerData &&
                streamerData.social &&
                streamerData.social.facebook &&
                streamerData.social.facebook.handle
              "
              :href="`https://facebook.com/${streamerData.social.facebook.handle}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icon icon="facebook" :thumbnail="true" width="20" height="20" />
            </a>

            <a
              v-if="parseTiktokHandle"
              :href="`https://tiktok.com/@${parseTiktokHandle}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icon icon="tiktok" :thumbnail="true" width="20" height="20" />
            </a>

            <a
              v-if="
                streamerData && streamerData.social && streamerData.social.trovo && streamerData.social.trovo.handle
              "
              :href="`https://trovo.live/${streamerData.social.trovo.handle}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Icon icon="trovo" :thumbnail="true" width="20" height="20" />
            </a>
          </div>
        </div>

        <div class="flex gap-4 my-4 md:mx-0 md:ml-auto">
          <Button kind="link" :class="currentTab === 0 ? 'active-tab' : ''" @clicked="currentTab = 0">
            Social media
          </Button>

          <Button kind="link" :class="currentTab === 1 ? 'active-tab' : ''" @clicked="currentTab = 1">Campaigns</Button>

          <Button kind="link" :class="currentTab === 2 ? 'active-tab' : ''" @clicked="currentTab = 2">Audience</Button>
          <Button
            v-if="this.$store.state.userInfo.type == 'admin'"
            kind="link"
            :class="currentTab === 3 ? 'active-tab' : ''"
            @clicked="currentTab = 3"
            >Clips</Button
          >
        </div>
      </div>
    </div>

    <!-- Social media tab -->
    <div v-if="currentTab === 0">
      <Title title="Social media metrics" size="medium" class="mb-4" />
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-20">
        <KPI label="Posts" :value="kpis && kpis.count && kpis.count > 0 ? kpis.count : 'N/A'" />

        <KPI label="Total followers" :value="totalFollowers && totalFollowers > 0 ? totalFollowers : 'N/A'" />

        <KPI label="Engagements" :value="totalEngagements && totalEngagements > 0 ? totalEngagements : 'N/A'" />

        <KPI
          label="Video views"
          :value="kpis && kpis.stats_video_views && kpis.stats_video_views > 0 ? kpis.stats_video_views : 'N/A'"
        />

        <KPI label="Reach" :value="kpis && kpis.stats_reach && kpis.stats_reach > 0 ? kpis.stats_reach : 'N/A'" />

        <KPI label="Impressions" :value="kpis && kpis.stats_impressions ? kpis.stats_impressions : 'N/A'" />
      </div>

      <Title
        v-if="followerProgressions && followerProgressions.length"
        title="Follower progression"
        size="medium"
        class="mb-4"
      />
      <div
        v-if="followerProgressions && followerProgressions.length"
        class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mb-20"
      >
        <div v-for="(platform, platformIndex) of followerProgressions" :key="platformIndex">
          <KPIChart
            class="h-full capitalize"
            :label="platform.platform"
            :labels="platform.dates"
            :chart-data="platform.values"
            :value="platform.values[platform.values.length - 1]"
          />
        </div>
      </div>
    </div>

    <!-- Campaigns tab -->
    <div v-else-if="currentTab === 1">
      <Title title="Campaign metrics" size="medium" class="mb-4" />
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-20">
        <KPI label="Ad views" :value="getTotalViews || 0" />

        <KPI label="Average ad viewers" :value="getTotalAverageViews || 0" />

        <KPI label="Link clicks" :value="getTotalClick || 0" />

        <KPI label="Link CTR" :value="getTotalLinkCtr ? `${getTotalLinkCtr}%` : 0" />

        <KPI label="Ad displays" :value="getTotalTimesDisplayed || 0" />

        <KPI label="Total earned" :value="calculateEarnings ? `€${calculateEarnings}` : 0" />
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-20">
        <div
          v-if="
            viewsByDay &&
            viewsByDay.labels &&
            viewsByDay.series &&
            viewsByDay.series[0] &&
            viewsByDay.series[0].data &&
            viewsByDay.series[0].data.length > 1
          "
        >
          <Title title="Campaign views" size="medium" />

          <AreaChart :series="viewsByDay.series" :labels="viewsByDay.labels" />
        </div>

        <div
          v-if="
            clicksByDay &&
            clicksByDay.labels &&
            clicksByDay.series &&
            clicksByDay.series[0] &&
            clicksByDay.series[0].data &&
            clicksByDay.series[0].data.length > 1
          "
        >
          <Title title="Campaign clicks" size="medium" />

          <AreaChart :series="clicksByDay.series" :labels="clicksByDay.labels" />
        </div>

        <div
          v-if="
            ctrByDay &&
            ctrByDay.labels &&
            ctrByDay.series &&
            ctrByDay.series[0] &&
            ctrByDay.series[0].data &&
            ctrByDay.series[0].data.length > 1
          "
        >
          <Title title="Campaign CTR" size="medium" />

          <AreaChart :series="ctrByDay.series" :labels="ctrByDay.labels" />
        </div>
      </div>

      <Title title="Campaigns" size="medium" class="mb-4" />
      <StreamerCampaigns :campaigns="campaignData" class="mb-20 w-full" :show-available-campaigns="false" />
    </div>

    <!-- Audience tab -->
    <div v-if="currentTab === 2">
      <StreamerAudience :handle="streamerHandle" />
    </div>
    <div v-if="currentTab === 3">
      <StreamerClips :handle="streamerHandle" :streamerid="streamerData._id" :profilecampaigns="campaignData" />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { Avatar, Button, Icon, KPI, KPIChart, Title, AreaChart } from "cavea-design-system";
import log from "@/lib/log";

export default {
  name: "StreamerProfile",

  components: {
    Avatar,
    Button,
    Icon,
    KPI,
    KPIChart,
    Title,
    AreaChart,
    StreamerAudience: () => import("@/components/streamer/StreamerAudience"),
    StreamerClips: () => import("@/components/streamer/StreamerClips"),
    StreamerCampaigns: () => import("@/components/streamer/StreamerCampaigns"),
  },

  data() {
    return {
      // NOTE: This could be rewritten to use child routes instead.
      // Currently not, to minimize the amount of calls
      currentTab: 0,
      streamerHandle: null,
      streamerData: null,
      campaignData: null,
      followerProgressions: [],
      totalAdViews: 0,
      adDisplays: 0,
      kpis: {
        stats_value: 0,
        stats_likes: 0,
        stats_comments: 0,
        stats_shares: 0,
        stats_impressions: 0,
        stats_reach: 0,
        stats_video_views: 0,
      },

      viewsGraph: null,
      clickGraph: null,
    };
  },

  computed: {
    getTotalViews() {
      let views = 0;

      if (this.campaignData?.length) {
        const campaignsWithStats = this.campaignData.filter(
          (campaign) => campaign?.handles[0]?.statsId?.stats?.length > 0
        );

        const getViews = (campaign) => campaign.handles[0].statsId.stats;

        const stats = _.flatMap(campaignsWithStats, getViews);

        views = stats.reduce((a, b) => a + (b.views || 0), 0);
      }

      return views;
    },

    getTotalClick() {
      let clicks = 0;

      if (this.campaignData?.length) {
        const campaignsWithClicks = this.campaignData.filter(
          (campaign) => campaign?.handles[0]?.redirectorId?.stats?.length > 0
        );

        for (const campaign of campaignsWithClicks) {
          clicks += campaign?.handles[0]?.redirectorId?.stats?.length || 0;
        }
      }

      return clicks;
    },

    getTotalTimesDisplayed() {
      let displayTimes = 0;

      if (this.campaignData) {
        for (const campaign of this.campaignData) {
          displayTimes += campaign?.handles[0]?.statsId?.stats?.length;
        }
      }

      return displayTimes;
    },

    getTotalAverageViews() {
      let avg = 0;

      if (this.getTotalViews && this.getTotalTimesDisplayed) {
        avg = (this.getTotalViews / this.getTotalTimesDisplayed).toFixed(1);
      }

      return parseFloat(avg);
    },

    getTotalLinkCtr() {
      let avg = 0;

      if (this.getTotalViews && this.getTotalClick) {
        avg = ((this.getTotalClick / this.getTotalViews) * 100).toFixed(2);
      }

      return parseFloat(avg);
    },

    calculateEarnings() {
      let total = 0.0;

      if (this.campaignData) {
        const campaignsWithStats = this.campaignData.filter(
          (campaign) => campaign?.handles[0]?.statsId?.stats?.length > 0
        );

        for (const campaign of campaignsWithStats) {
          const views = campaign?.handles[0]?.statsId?.stats?.reduce((a, b) => a + (b.views || 0), 0);

          let cpm = campaign.info.priceRate;

          if (typeof campaign.handles[0].priceRate === "number" && !Number.isNaN(campaign.handles[0].priceRate)) {
            cpm = campaign.handles[0].priceRate;
          }

          const earned = (views / 1000) * cpm; // (views / 1000) * cpm. Could also be written views/100, but i feel this is more readable.

          total += earned;
        }
      }

      return parseFloat(total.toFixed(1));
    },

    totalFollowers() {
      let followers = 0;

      for (let i = 0; i < this.followerProgressions?.length; ++i) {
        if (this.followerProgressions[i]?.values?.length) {
          followers += this.followerProgressions[i]?.values[this.followerProgressions[i]?.values?.length - 1];
        }
      }

      return followers;
    },

    parseTiktokHandle() {
      if (this?.streamerData?.social?.tiktok?.handle?.indexOf("@") === 0) {
        return this?.streamerData?.social?.tiktok?.handle.slice(1, this?.streamerData.social.tiktok.handle.length);
      }

      if (this?.streamerData?.social?.tiktok?.handle) {
        return this?.streamerData?.social?.tiktok?.handle;
      }

      return null;
    },

    totalEngagements() {
      let total = 0;

      if (this.kpis?.stats_comments && typeof this.kpis?.stats_comments === "number") {
        total += this.kpis?.stats_comments;
      }
      if (this.kpis?.stats_likes && typeof this.kpis?.stats_likes === "number") {
        total += this.kpis?.stats_likes;
      }
      if (this.kpis?.stats_shares && typeof this.kpis?.stats_shares === "number") {
        total += this.kpis?.stats_shares;
      }

      return total;
    },

    viewsByDay() {
      if (!this.viewsGraph?.length) {
        return null;
      }

      return {
        series: [
          {
            name: "Total views",
            data: this.viewsGraph.map((item, index) => item?.total || this.viewsGraph[index - 1]?.total + item.views),
          },
          {
            name: "Views by day",
            data: this.viewsGraph.map((item) => item?.views || 0),
          },
          {
            name: "Average views",
            data: this.viewsGraph.map((item) => Math.round((item?.views || 0) / (item?.count || 0))),
          },
        ],

        labels: this.viewsGraph?.map((item) => item?.date),
      };
    },

    clicksByDay() {
      if (!this.clickGraph?.length) {
        return null;
      }

      return {
        series: [
          {
            name: "Total clicks",
            data: this.clickGraph.map(
              (item, index) => item?.total || this.clickGraph[index - 1]?.total + (item?.clicks || 0)
            ),
          },
          {
            name: "Clicks by day",
            data: this.clickGraph.map((item) => item?.clicks),
          },
        ],

        labels: this.clickGraph?.map((item) => item?.date),
      };
    },

    ctrByDay() {
      if (!this.clickGraph?.length || !this.viewsGraph?.length) {
        return null;
      }

      const labels = [];
      const series = [
        {
          name: "Total CTR",
          data: [],
        },
        {
          name: "CTR by day",
          data: [],
        },
      ];

      let currentTotalClicks = 0;
      for (let i = 0; i < this.viewsGraph?.length; ++i) {
        const clickIndex = this.clickGraph.findIndex((c) => c.date === this.viewsGraph[i].date);

        labels.push(this.viewsGraph[i].date);

        if (clickIndex !== -1) {
          currentTotalClicks = this.clickGraph[clickIndex].total;
        }
        series[0].data.push(
          parseFloat(
            (
              ((this.clickGraph[clickIndex === -1 ? currentTotalClicks : clickIndex]?.total || 0) /
                (this.viewsGraph[i]?.total || 0)) *
              100
            )?.toFixed(2)
          )
        );
        series[1].data.push(
          parseFloat(
            (
              ((this.clickGraph[clickIndex === -1 ? 0 : clickIndex]?.clicks || 0) / (this.viewsGraph[i]?.views || 0)) *
              100
            )?.toFixed(2)
          )
        );
      }

      return {
        labels,
        series,
      };
    },
  },

  watch: {
    streamerData: {
      handler() {
        if (this.streamerData?._id) {
          this.fetchKpis();

          if (!this.viewsGraph) {
            this.fetchViewsGraph();
          }

          if (!this.clickGraph) {
            this.fetchClickGraph();
          }
        }

        if (this.streamerData?.social && Object.keys(this.streamerData?.social).length) {
          for (const platform of Object.keys(this.streamerData?.social)) {
            if (platform !== "twitch") {
              this.fetchFollowerProgression(platform, this.streamerData?.social[platform]?.handle);
            }
          }
        }
      },
    },
  },

  created() {
    this.streamerHandle = this.$route.params.handle;

    this.getStreamerData(this.streamerHandle);

    this.fetchFollowerProgression("twitch", this.streamerHandle);

    if (this.streamerData?._id) {
      this.fetchKpis();

      if (!this.viewsGraph) {
        this.fetchViewsGraph();
      }

      if (!this.clickGraph) {
        this.fetchClickGraph();
      }
    }
  },

  methods: {
    async getStreamerData(handle) {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/content-creator/profile/${handle}`;

      const result = await axios
        .get(URL)
        .then((res) => res.data)
        .catch((err) => console.error(err));

      this.streamerData = result.userInfo;
      this.campaignData = result.campaigns;
    },

    async fetchFollowerProgression(platform, handle) {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/follower-progression/${platform}/${handle}?days=7`;

      const response = await axios
        .get(URL)
        .then((res) => res?.data)
        .catch((error) => {
          console.error("Error getting follower count", error);
          return null;
        });

      if (response?.dates?.length && response?.values?.length) {
        const existing = this.followerProgressions.findIndex((f) => f.platform === platform);

        if (existing !== -1) this.followerProgressions.splice(existing, 1);

        this.followerProgressions.push({
          platform,
          dates: response?.dates,
          values: response?.values,
        });
      }
    },

    async fetchKpis() {
      log({ fn: "fetchKpis" });
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/content-creator/kpis/${this.streamerData._id}`;

      const response = await axios
        .get(URL)
        .then((res) => res?.data?.stats)
        .catch((error) => {
          console.error("fetchKpis error", error);
          return null;
        });

      this.kpis = response;
    },

    async fetchViewsGraph() {
      log({ fn: "fetchViewsGraph" });
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/views-by-day/${this.streamerData._id}`;

      const result = await axios
        .get(URL)
        .then((res) => res?.data?.stats || null)
        .catch((error) => {
          console.error("fetchViewsGraph error", error);
          return null;
        });

      if (result) {
        this.viewsGraph = result;
      }
    },

    async fetchClickGraph() {
      log({ fn: "fetchClickGraph" });

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/agg-clicks-by-day/${this.streamerData._id}`;
      const result = await axios
        .get(URL)
        .then((res) => res?.data?.stats)
        .catch((error) => {
          console.error("fetchClickGraph error", error);
          return null;
        });

      if (result) {
        this.clickGraph = result;
      }
    },
  },
};
</script>

<style lang="scss">
.active-tab > button {
  color: #34c66e !important;
}
</style>
